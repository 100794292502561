@import "./common.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

/* ============  Start Custom CSS =========== */

/* APP layout */
.app_wrapper {
  display: grid;
  @include fullDisplay;

  .app_page {
    margin: 5px 16px;

    .page_header {
      margin-bottom: 2px;
    }

    .page_content {
      padding: 16px 24px;
      background: #fff;
      min-height: 900px;
    }
  }
}

.form_wrapper {
  @include flexContentCenter;

  .form {
    width: 90%;
    max-width: 1366px;
    border: 1px solid $border_color;
    padding: $padding;
  }

  .title {
    text-align: center;
  }

  .form_button {
    display: block;
    width: 100%;
  }
}

.canceled_row {
  background-color: rgb(255, 226, 226);
}
